define('ember-radio-button/components/radio-button', ['exports', 'ember', 'ember-radio-button/components/radio-button-base'], function (exports, _ember, _emberRadioButtonComponentsRadioButtonBase) {
  'use strict';

  var computed = _ember['default'].computed;
  var on = _ember['default'].on;

  exports['default'] = _emberRadioButtonComponentsRadioButtonBase['default'].extend({
    value: null,
    groupValue: null,

    wrapInLabelIfUsedAsBlock: on('init', function () {
      if (this.get('template')) {
        this.set('tagName', 'label');
        this.set('layoutName', 'components/labeled-radio-button');

        // our change event handler becomes unused
        this.set('change', undefined);

        // don't bind name, type, etc to the label
        var originalAttrs = this.get('attributeBindings');
        var updatedAttrs = _ember['default'].copy(originalAttrs).removeObjects(_emberRadioButtonComponentsRadioButtonBase.boundAttributeKeys);
        this.set('attributeBindings', updatedAttrs);
        this.get('classNameBindings').pushObject('checked');
        this.get('classNames').pushObject('ember-radio-button');
      }
    }),

    checked: computed('groupValue', 'value', function () {
      return this.get('groupValue') === this.get('value');
    }).readOnly(),

    change: function change() {
      var value = this.get('value');
      var groupValue = this.get('groupValue');

      if (groupValue !== value) {
        this.set('groupValue', value);
        _ember['default'].run.once(this, 'sendChangedAction');
      }
    },

    sendChangedAction: function sendChangedAction() {
      this.sendAction('changed', this.get('value'));
    },

    actions: {
      // when used as a block, our layout wraps a non-block
      // radio-button which maps changed to this
      innerRadioChanged: function innerRadioChanged(value) {
        this.sendAction('changed', value);
      }
    }
  });
});