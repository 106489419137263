define('ember-cli-fastclick/initializers/fastclick', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    if (typeof FastBoot === 'undefined') {
      Ember.run.schedule('afterRender', function () {
        FastClick.attach('body');
      });
    }
  }

  exports['default'] = {
    initialize: initialize
  };
});