define('ember-radio-button/components/radio-button-base', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var boundAttributeKeys = ['checked', 'disabled', 'name', 'required', 'type', 'value'];

  exports.boundAttributeKeys = boundAttributeKeys;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    type: 'radio',
    value: null,
    defaultLayout: null, // ie8 support

    attributeBindings: boundAttributeKeys
  });
});